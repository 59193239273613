import {
    AbstractControl,
    UntypedFormControl,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { debounceTime, tap } from 'rxjs/operators';
import { EMAIL_REGEX, VARIABLE_REGEX } from 'src/app/app.constants';
import moment from 'moment';
import { isFunction } from 'lodash';

type GetValuesFn = () => Array<any>;

export class CustomValidators {
    static notIsZero(control: UntypedFormControl) {
        if (control.value === null || control.value === '') {
            return null;
        }
        return control.value === 0 ? { zeroValue: true } : null;
    }

    static noWhitespaceValidator(control: UntypedFormControl) {
        if (control.value === null || control.value === '') {
            return null;
        }

        if (control.value && !(control.value instanceof Date)) {
            const isWhitespace =
                (control.value?.toString() || '').trim().length === 0;
            return isWhitespace ? { whitespace: true } : null;
        }
    }

    static checkPasswordsValidator(control: UntypedFormControl) {
        const form = control.parent;
        if (
            form &&
            form.get('password').value !== form.get('confirmPassword').value
        ) {
            return { passwordNotEqual: true };
        }
        return null;
    }

    static isPasswordValid(control: UntypedFormControl) {
        if (control.value === null || control.value === '') {
            return null;
        }

        // Check if it has at least 8 characters
        if (control.value.length < 8) {
            return { invalidPassword: true };
        }

        // Check if it has at least one lowercase letter
        if (!/[a-z]/.test(control.value)) {
            return { invalidPassword: true };
        }

        // Check if it has at least one uppercase letter
        if (!/[A-Z]/.test(control.value)) {
            return { invalidPassword: true };
        }

        // Check if it has at least one digit
        if (!/\d/.test(control.value)) {
            return { invalidPassword: true };
        }

        // Check if it has at least one special character
        if (!/[^a-zA-Z0-9]/.test(control.value)) {
            return { invalidPassword: true };
        }

        return null;
    }

    static phone(control: AbstractControl) {
        if (control.value) {
            const isValid = isValidPhoneNumber(control.value, {
                defaultCountry: 'DE',
                defaultCallingCode: '49',
            });
            if (!isValid) {
                return { phoneNumberInvalid: !isValid };
            }
        }
        return null;
    }

    static checkDateStringContent(date: string) {
        if (date) {
            const str = date.split('/');
            if (str[0] && str[0].length > 2 && str[1]) {
                date =
                    str[0].slice(0, 2) +
                    '.' +
                    str[0][2] +
                    str[1][0] +
                    '.' +
                    str[1][1] +
                    str[2];
            } else if (str[1] && str[1].length > 2) {
                date =
                    str[0] +
                    '.' +
                    str[1].slice(0, 2) +
                    '.' +
                    str[1][2] +
                    str[2];
            }

            return date;
        }
    }

    static min(minLimit) {
        return (control) => {
            if (
                CustomValidators.isEmptyInputValue(control.value) ||
                CustomValidators.isEmptyInputValue(minLimit)
            ) {
                return null; // don't validate empty values to allow optional controls
            }
            const value = parseFloat(
                control.value.toString().replace(',', '.')
            );
            return !isNaN(value) && value < minLimit
                ? { min: { min: minLimit, actual: control.value } }
                : null;
        };
    }

    private static isEmptyInputValue(value) {
        return value == null || value.length === 0;
    }
    static max(maxLimit) {
        return (control) => {
            if (
                CustomValidators.isEmptyInputValue(control.value) ||
                CustomValidators.isEmptyInputValue(maxLimit)
            ) {
                return null;
            }
            const value = parseFloat(
                control.value.toString().replace(',', '.')
            );
            return !isNaN(value) && value > maxLimit
                ? { max: { max: maxLimit, actual: control.value } }
                : null;
        };
    }

    static dateLowerThanOtherDate(anotherControlName: string) {
        return (control: UntypedFormControl) => {
            const parentForm = control.parent;
            if (parentForm) {
                const otherDate = parentForm.controls[anotherControlName];
                if (
                    CustomValidators.isEmptyInputValue(control.value) ||
                    CustomValidators.isEmptyInputValue(otherDate.value)
                ) {
                    return null;
                }
                if (moment(control.value).isAfter(otherDate.value)) {
                    return { matDatepickerMax: true };
                } else {
                    const hasOtherDateError = parentForm
                        .get(anotherControlName)
                        .hasError('matDatepickerMin');
                    if (hasOtherDateError) {
                        delete parentForm.get(anotherControlName).errors[
                            'matDatepickerMin'
                        ];
                        parentForm
                            .get(anotherControlName)
                            .updateValueAndValidity();
                    }
                }
            }
            return null;
        };
    }

    static dateGreaterThanOtherDate(anotherControlName: string) {
        return (control: UntypedFormControl) => {
            const parentForm = control.parent;
            if (parentForm) {
                const otherDate = control.parent.controls[anotherControlName];
                if (
                    CustomValidators.isEmptyInputValue(control.value) ||
                    CustomValidators.isEmptyInputValue(otherDate.value)
                ) {
                    return null;
                }
                if (moment(control.value).isBefore(otherDate.value)) {
                    return { matDatepickerMin: true };
                } else {
                    const hasOtherDateError = parentForm
                        .get(anotherControlName)
                        .hasError('matDatepickerMax');
                    if (hasOtherDateError) {
                        delete parentForm.get(anotherControlName).errors[
                            'matDatepickerMax'
                        ];
                        parentForm
                            .get(anotherControlName)
                            .updateValueAndValidity();
                    }
                }
            }
            return null;
        };
    }

    /**
     * Validation to verify that the form field value exists within the given list of values;
     *
     * @param values - list of correct values to compare;
     * @param compareFn - function to compare one value to another, where "a" is the value
     *                    from the control form and "b" is a value from the given list of values;
     * @param customError - validation error map, default value is { nonPresentValue: true };
     *
     * @return A validation @see {@link AbstractControl } with an error map with the `nonPresentValue`
     *         property if the validation check fails, otherwise `null`.
     */
    static includes(
        values: Array<any> | GetValuesFn,
        compareFn?: (item1, item2) => boolean,
        customError: any = { nonPresentValue: true }
    ) {
        return (control: AbstractControl) => {
            const rawValues = isFunction(values) ? values() : values;
            const controlValue = control.value;
            let error = null;
            if (controlValue) {
                const contains =
                    rawValues.filter((item) => {
                        return compareFn
                            ? compareFn(controlValue, item)
                            : controlValue === item;
                    }).length > 0;
                if (!contains) {
                    error = customError;
                }
            }
            return error;
        };
    }

    static email(control: AbstractControl) {
        if (control.value) {
            const isPatternInvalid = Validators.pattern(EMAIL_REGEX)(control);
            if (isPatternInvalid) {
                return { invalidEmail: true };
            }
        }
        return null;
    }

    /**
     * Validation to verify that the form field value not exists within the given list of values.
     * Use the function @see {@link CustomValidators.includes } to perform validation and reverse the result;
     *
     * @param values - list of wrong values to compare;
     * @param compareFn - function to compare one value to another, where "a" is the value
     *                    from the control form and "b" is a value from the given list of values;
     * @param customError - validation error map, default value is { valuePresent: true };
     *
     * @return A validation @see {@link AbstractControl } with an error map with the `valuePresent`
     *         property if the validation check fails, otherwise `null`.
     */
    static notInclude(
        values: Array<any> | GetValuesFn,
        compareFn?: (item1, item2) => boolean,
        customError: any = { valuePresent: true }
    ) {
        return (control: AbstractControl) => {
            return this.includes(values, compareFn)(control)
                ? null
                : customError;
        };
    }

    /**
     * Validation to verify that the form field value exists only numbers;
     *
     * @param customError - validation error map, default value is { onlyNumbers: true };
     *
     * @return A validation @see {@link AbstractControl } with an error map with the `onlyNumbers`
     *         property if the validation check fails, otherwise `null`.
     */
    static onlyNumbers(customError: any = { onlyNumbers: true }) {
        return (control: AbstractControl) => {
            const controlValue = control.value;
            let error = null;
            let containsError = false;
            if (controlValue) {
                if (Array.isArray(controlValue)) {
                    containsError =
                        controlValue.filter((e) => /[^0-9]/.test(e)).length > 0;
                } else {
                    containsError = /[^0-9]/.test(controlValue);
                }
                if (containsError) {
                    error = customError;
                }
            }
            return error;
        };
    }

    /** Validator to check if the current field will be valid if it meets the FN condition */
    static validate(
        validator: (control: AbstractControl) => ValidationErrors | null
    ) {
        return {
            if: (fn: (control: AbstractControl) => boolean) => {
                return (control: AbstractControl) => {
                    if (fn(control)) {
                        return validator(control);
                    }
                    return null;
                };
            },
        };
    }

    static variableValidator(control: AbstractControl) {
        if (control.value) {
            Validators.pattern(VARIABLE_REGEX)(control);
        }
        return null;
    }

    /**
     * Util method to enable or disable fields when the control param has a value change
     *
     * @param control - main field, we noticed changes in it
     *                  to enable or disable the list of dependent fields;
     * @param dependentFields - subordinate fields;
     * @param fn - validation.
     */
    static enableFieldsOnValueChange(
        control: AbstractControl,
        dependentFields: Array<AbstractControl>,
        fn: (control: AbstractControl) => boolean
    ) {
        control.valueChanges.pipe(debounceTime(300)).subscribe(() => {
            dependentFields.forEach((field) => {
                if (fn(field)) {
                    field.enable();
                } else {
                    field.disable();
                }
            });
        });
    }

    /**
     * Validation to verify that the form field value exists only positive numbers;
     *
     * @param customError - validation error map, default value is { onlyPositiveNumbers: true };
     *
     * @return A validation @see {@link AbstractControl } with an error map with the `onlyPositiveNumbers`
     *         property if the validation check fails, otherwise `null`.
     */
    static onlyPositiveNumbers(
        customError: any = { onlyPositiveNumbers: true }
    ) {
        return (control: AbstractControl) => {
            const controlValue = control.value;
            let error = null;
            let containsError = false;
            const { onlyNumbers } = this.onlyNumbers()(control) ?? {
                onlyNumbers: false,
            };
            if (!onlyNumbers && controlValue) {
                if (Array.isArray(controlValue)) {
                    containsError =
                        controlValue.filter(
                            (e) =>
                                !/^([+-]?)([0-9]*)([\,\.]?)([0-9]+)/.test(e) ||
                                Number(e) < 0
                        ).length > 0;
                } else {
                    containsError =
                        !/^([+-]?)([0-9]*)([\,\.]?)([0-9]+)/.test(
                            controlValue
                        ) || Number(controlValue) < 0;
                }
                if (containsError) {
                    error = customError;
                }
            }
            return error;
        };
    }

    /**
     * Validation to verify that the form field value exists only positive numbers;
     *
     * @param customError - validation error map, default value is { onlyNegativeNumbers: true };
     *
     * @return A validation @see {@link AbstractControl } with an error map with the `onlyNegativeNumbers`
     *         property if the validation check fails, otherwise `null`.
     */
    static onlyNegativeNumbers(
        customError: any = { onlyNegativeNumbers: true }
    ) {
        return (control: AbstractControl) => {
            const controlValue = control.value;
            let error = null;
            let containsError = false;
            const { onlyNumbers } = this.onlyNumbers()(control) ?? {
                onlyNumbers: false,
            };
            if (!onlyNumbers && controlValue) {
                if (Array.isArray(controlValue)) {
                    containsError =
                        controlValue.filter(
                            (e) =>
                                !/^([\+\-]?)([0-9]*)([\,\.]?)([0-9]+)/.test(
                                    e
                                ) || Number(e) >= 0
                        ).length > 0;
                } else {
                    containsError =
                        !/^([\+\-]?)([0-9]*)([\,\.]?)([0-9]+)/.test(
                            controlValue
                        ) || Number(controlValue) >= 0;
                }
                if (containsError) {
                    error = customError;
                }
            }
            return error;
        };
    }

    /** Validator to check if the current field will be mandatory if it meets the FN condition */
    static requiredIf(fn: (control: AbstractControl) => boolean) {
        return (control: AbstractControl) => {
            if (fn(control)) {
                return Validators.required(control);
            }
            return null;
        };
    }
}
