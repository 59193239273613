<div [class.error-message]="showEmpty">
    <div *ngIf="hasSomeError(); else elseTemplate">
        <small class="form-text text-danger" *ngFor="let error of errors()">
            {{ "validations." + error | translate }}
        </small>
    </div>

    <ng-template #elseTemplate>
        <small class="form-text text-muted" *ngIf="required()" translate>
            {{ messages?.required || "validations.required" }}
        </small>
    </ng-template>
</div>
