<ng-content *ngIf="!hideHeader" select="[header]"></ng-content>
<div class="close-container">
    <i *ngIf="!disableClose" class="close material-icons-outlined" (click)="dialogClose()"
        [matTooltip]="buttonCloseTranslation" [matTooltipShowDelay]="200">close</i>
</div>
<h1 *ngIf="title" class="text-center text-muted modal-title mt-3" mat-dialog-title [innerHtml]="title | translate"></h1>
<div [ngClass]="bodyClass">
    <mat-dialog-content>
        <ng-content select="[content]"></ng-content>
    </mat-dialog-content>

    <mat-dialog-actions class="mt-4" [style]="justifyContent" *ngIf="showActions">
        <ng-content select="[actions]"></ng-content>
    </mat-dialog-actions>
</div>
